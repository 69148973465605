import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import "./App.css";
import { Typography } from "@material-ui/core";
import ReactGA from "react-ga";

const World = lazy(() => import("./components/World"));
const Country = lazy(() => import("./components/Country"));
const Region = lazy(() => import("./components/Region"));
const Sovereignty = lazy(() => import("./components/Sovereignty"));
const TableauView = lazy(() => import("./components/TabularView"));
const About = lazy(() => import("./components/About"));
const Contact = lazy(() => import("./components/Contact"));
const TodoList = lazy(() => import("./components/TodoList"));
const FinWorld = lazy(() => import("./components/FinWorld"));

ReactGA.initialize("UA-102326814-7");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <React.Fragment>
            {/* <CustomNavBar /> */}

            <Switch>
              {/* <Route exact path="/" component={Home}></Route> */}
              <Route exact path="/" component={World}></Route>
              <Route exact path="/world" component={World}></Route>
              <Route path="/country/:id" component={Country}></Route>
              <Route path="/region/:cid/:id" component={Region}></Route>
              <Route
                path="/locality/:cid/:rid/:id"
                component={Sovereignty}
              ></Route>
              <Route exact path="/tabular" component={TableauView}></Route>
              <Route exact path="/about" component={About}></Route>
              <Route exact path="/contact" component={Contact}></Route>
              <Route exact path="/todo" component={TodoList}></Route>

              {/* COVID-19  */}
              <Route exact path="/ncovid19" component={World}></Route>
              <Route exact path="/ncovid19/world" component={World}></Route>
              <Route path="/ncovid19/country/:id" component={Country}></Route>
              <Route
                path="/ncovid19/region/:cid/:id"
                component={Region}
              ></Route>
              <Route
                path="/ncovid19/locality/:cid/:rid/:id"
                component={Sovereignty}
              ></Route>
              <Route
                exact
                path="/ncovid19/tabular"
                component={TableauView}
              ></Route>

              {/* GDP */}
              <Route exact path="/econ" component={FinWorld}></Route>
              <Route exact path="/econ/world" component={FinWorld}></Route>
              <Route path="/econ/country/:id" component={Country}></Route>
              <Route path="/econ/region/:cid/:id" component={Region}></Route>
              <Route exact path="/econ/tabular" component={TableauView}></Route>
            </Switch>

            <BottomNavigation
              // value={value}
              // onChange={(event, newValue) => {
              //   setValue(newValue);
              // }}
              // showLabels
              //className={classes.root}
              style={{
                backgroundColor: "#000000",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: "#FFFFFF",
                }}
              >
                From Jamaica with Love &copy;
              </Typography>
            </BottomNavigation>
          </React.Fragment>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
